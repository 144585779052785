import React, { useEffect, useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, signOut } from '@firebase/auth';
import { auth, db } from 'firebaseconfig';
import styled from 'styled-components';
import Logo from 'assets/images/logo_image.svg';
import LoginButton from 'assets/images/icons/button.svg';
import HoveredGoogle from 'assets/images/icons/hovered_google.svg';
import { useGoogleLogin } from '@react-oauth/google';
import SuccessLogin from './SuccessLogin';
import Icon from '../components/Icon';
import {jwtDecode} from "jwt-decode";
import axios from "axios";

const SCOPE = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile openid';

const commentsData = [
    {
        bigText: '$37B',
        text: 'approximate cost that companies spend on meetings annually.',
        link: 'https://hbr.org/2018/02/how-to-fix-the-most-soul-crushing-meetings',
        name: 'Harvard Business Review',
    },
    {
        bigText: '252%',
        text: 'increase in meeting time for the typical employee since February 2020',
        link: 'https://www.microsoft.com/en-us/worklab/work-trend-index/great-expectations-making-hybrid-work-work',
        name: 'Microsoft report',
    },
    {
        bigText: '$25000',
        text: 'costs of the meeting time per employee, annually.',
        link: 'https://www.nytimes.com/2023/04/07/business/office-meetings-time.html',
        name: 'NY Times',
    },
];

const Login = () => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [email, setEmail] = useState('');

    const initialUserProperties = {
        access_token: '',
        expires_in: 0,
        id_token: '',
        scope: '',
        token_type: '',
    };
    const [emailUser, setEmailUser] = useState(initialUserProperties);

    const getRefreshToken = async (codeResponse) => {
        const payload = {
            grant_type: 'authorization_code',
            code: codeResponse.code,
            client_id: '786136769301-40r6jgc8far7sb0v86tpgoag9epmktsc.apps.googleusercontent.com',
            client_secret: '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',
            redirect_uri: window.location.origin,
        };

        try {
            const response = await axios.post('https://oauth2.googleapis.com/token', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Token Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error exchanging code for tokens:', error);
            throw error;
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser ? currentUser : null);
        });
        return () => unsubscribe();
    }, []);

    const login = useGoogleLogin({
        scope: SCOPE,
        flow: 'auth-code',
        redirect_uri: window.location.origin,
        onSuccess: async (codeResponse) => {
            let refreshToken;
            let accessToken;
            let idToken;
            let email;
            let displayName;
            console.log(emailUser);
            if (codeResponse.scope.includes('https://www.googleapis.com/auth/calendar')) {
                try {
                    const emailUser = await getRefreshToken(codeResponse, setEmailUser);
                    if (emailUser.refresh_token) {
                        refreshToken = emailUser.refresh_token;
                        accessToken = emailUser.access_token;
                        idToken = emailUser.id_token;

                        const decoded = jwtDecode(idToken);
                        setUser(decoded);
                        email = decoded.email;
                        displayName = decoded.name;
                        loginExtension({
                            accessToken,
                            refreshToken,
                            idToken,
                            email,
                            displayName,
                        });

                    }
                } catch (error) {
                    console.error('Error during login process:', error);
                }
            }
        },
        onError: (error) => {
            console.log('Login Failed:', error);
        },
    });

    const handleRightClick = () => {
        setCurrentIndex((prevState) => (prevState + 1) % commentsData.length);
    };

    const handleLeftClick = () => {
        setCurrentIndex(
            (prevState) =>
                (prevState - 1 + commentsData.length) % commentsData.length
        );
    };

    useEffect(() => {
        signOut(auth)
            .then(() => {
                console.log('User logged out successfully');
            })
            .catch((error) => {
                console.error('Error logging out: ', error);
            });
    }, []);

    const loginExtension = (userInfo) => {
        const variant = 'p';
        var editorExtensionId = 'kioknbjoaogjjfmbbiafkagepdhhfjje';

        chrome.runtime.sendMessage(
            editorExtensionId,
            { user: userInfo, action: 'login' },
            function (response) {
                if (!response.success) console.log(response);
            }
        );

        chrome.runtime.sendMessage(
            "neeejkmamhpfacjdnbeoblbkplgdgihf",
            { user: userInfo, action: 'login' },
            function (response) {
                if (!response.success) console.log(response);
            }
        );
    };

    const handleCLickPrivacyPolicy = (e) => {
        e.preventDefault();
        window.open('/privacy_policy', '_blank');
    };

    const handleClickTermsOfUse = (e) => {
        e.preventDefault();
        window.open('/terms_of_use', '_blank');
    };

    const getArrowColor = () => {
        if (isHovered) {
            return 'YellowRightArrow';
        }
        return 'WhiteRightButton';
    };

    return user ? (
        <SuccessLogin />
    ) : (
        <>
            <MainContainer>
                <LeftBox>
                    <StyledDiv>
                        <LeftB onClick={handleLeftClick}>
                            <Icon type="WhiteLeftButton" />
                        </LeftB>
                        <CommentsContainer>
                            <BigText>
                                {commentsData[currentIndex].bigText}
                            </BigText>
                            <Text>{commentsData[currentIndex].text}</Text>
                            <Link
                                href={commentsData[currentIndex].link}
                                target="_blank"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <StyledLink isHovered={isHovered}>
                                    {commentsData[currentIndex].name}
                                </StyledLink>
                                <Icon
                                    type={getArrowColor()}
                                    style={{ width: '16px', height: '16px' }}
                                />
                            </Link>
                        </CommentsContainer>
                        <RightB onClick={handleRightClick}>
                            <Icon type="WhiteRightButton" />
                        </RightB>
                    </StyledDiv>
                </LeftBox>
                <RightBox>
                    <LoginContainer>
                        <LogoImage
                            src={Logo}
                            onClick={() => {
                                window.location.href = 'https://spryplan.com';
                            }}
                        />
                        <TextHeading>
                            Sign up to get all the features
                        </TextHeading>
                        <ChromeLogin
                            src={LoginButton}
                            onClick={() => login()}
                        />
                        <UserInfo>
                            <Terms>
                                By using Spry Plan you agree to the{' '}
                                <CustomLink onClick={handleClickTermsOfUse}>
                                    Terms of Use
                                </CustomLink>{' '}
                                and{' '}
                                <CustomLink onClick={handleCLickPrivacyPolicy}>
                                    Privacy Policy
                                </CustomLink>
                            </Terms>
                        </UserInfo>
                    </LoginContainer>
                </RightBox>
            </MainContainer>
        </>
    );
};

export default Login;

const StyledImage = styled.img`
    margin-left: 5px;
    width: 16px;
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: ${(props) => (props.isHovered ? '#ffcf40' : 'white')};
    margin-right: 8px;
`;

const Link = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
`;

const MainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
`;

const LeftBox = styled.div`
    height: auto;
    width: 50%;
    position: relative;
    background-color: #140027;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LeftB = styled.div`
    width: 40px;
    height: 40px;
    margin-left: 38px;
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
        border-color: rgba(255, 255, 255, 0.8);
    }
`;

const RightB = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 38px;
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
        border-color: rgba(255, 255, 255, 0.8);
    }
`;

const CommentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 485px;
`;

const Text = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Inter';
    color: #ffffff;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const LeftButton = styled.img`
    cursor: pointer;
    margin-left: 38px;
    //margin-left: 2vh;
`;

const RightButton = styled.img`
    cursor: pointer;
    margin-right: 38px;
    transition: all 0.3s ease-in-out;
`;

const RightBox = styled.div`
    width: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const LogoImage = styled.img`
    width: 132px;
    height: 24px;
    margin-bottom: 224px;
    margin-top: 50px;
    cursor: pointer;
`;

const TextHeading = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-size: 3vh;
    display: flex;
    color: #000000;
`;

const ChromeLogin = styled.img`
    cursor: pointer;
    margin-top: 64px;
    width: 450px;
    height: 50px;
    transition: all 0.3s ease-in-out;

    &:hover {
        content: url(${HoveredGoogle});
    }
`;

const BigText = styled.div`
    font-family: 'Inter';
    font-size: 8vh;
    color: #ffffff;
    font-weight: 500;
    line-height: 82px;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const Terms = styled.div`
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.5vh;
    margin-top: 342px;
    margin-bottom: 24px;
`;

const CustomLink = styled.a`
    text-decoration: none;
    color: #146ef6;
    cursor: pointer;
    font-family: 'Inter';
`;
